<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户昵称：">
        <el-input size="small" v-model="searchForm.keyword"></el-input>
      </el-form-item>
      <el-form-item label="店铺/名称：">
        <el-input size="small" v-model="searchForm.name"></el-input>
      </el-form-item>
      <el-form-item label="案例名称：">
        <el-input size="small" v-model="searchForm.case_name"></el-input>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader
          v-model="searchForm.city_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label=" " label-width="18px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nickname" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="店铺/名称" width="200" align="center"></el-table-column>
      <el-table-column prop="location" label="广告位置" align="center">
        <template v-slot="{ row }">
          {{
            row.location == 1
              ? '首页轮播图'
              : row.location == 2
              ? '论坛轮播图'
              : row.location == 3
              ? '商城轮播图'
              : row.location == 4
              ? '首页推荐商家'
              : row.location == 5
              ? '论坛推荐商家'
              : '首页案例'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="service_type" label="广告图片" align="center" width="280px">
        <template v-slot="{ row }">
          <div class="image">
            <el-image style="width: 100px; height: 70px" :src="row.image" :preview-src-list="[row.image]"></el-image>
            <video v-if="row.video" controls :src="row.video"></video>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="day" label="申请天数" align="center"></el-table-column>
      <el-table-column prop="city_name" label="投放区域" align="center"></el-table-column>
      <el-table-column prop="create_time" label="提交时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" align="center">
        <template v-slot="{ row }">
          <span :style="{ color: row.status == 1 ? '#409EFF' : row.status == 0 ? '#e6a23c' : '#f56c6c' }">
            {{ row.status == 1 ? '已通过' : row.status == 0 ? '待审核   ' : '未通过' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <div class="btn">
            <el-button
              v-if="row.location == 6"
              @click="
                $router.push({
                  path:'/service/orderInfo',
                  query: {
                    id: row.order_id,
                  },
                })
              "
              type="text"
              size="small"
            >
              查看订单
            </el-button>
            <el-button @click="seeLog(row.id)" type="text" size="small">查看</el-button>
            <el-button v-if="row.status == 0" @click="audit(row)" type="text" size="small">审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="审核" :visible.sync="dislogaudit" width="600px">
      <el-form class="auditForm" ref="form" :model="auditForm" :rules="rules">
        <el-form-item label="请选择审核结果："></el-form-item>
        <el-form-item>
          <el-radio-group v-model="auditForm.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="auditForm.status == 1" label="广告价格：" prop="price">
          <el-input v-model="auditForm.price"></el-input>
        </el-form-item>
        <el-form-item v-if="auditForm.status == -1" label="请添加驳回原因：" prop="reason">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="auditForm.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogaudit = !1">取 消</el-button>
        <el-button type ="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核记录" :visible.sync="disloglog" width="700px">
      <el-table :data="auditLog" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="status" label="审核结果" align="center">
          <template v-slot="{ row }">{{ row.status == 1 ? '通过' : '驳回' }}</template>
        </el-table-column>
        <el-table-column prop="reason" label="审核详情" align="center">
          <template v-slot="{ row }">{{ row.reason || '--' }}</template>
        </el-table-column>
        <el-table-column prop="operator_name" label="操作人名称" align="center"></el-table-column>
        <el-table-column prop="create_time" label="提交时间" width="200" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="total1" :page="page" :pageNum="rows" @updatePageNum="updateData1"></Paging>
      <span slot="footer" class="dialog-footer">
        <el-button @click="disloglog = !1">取 消</el-button>
        <el-button type="primary" @click="disloglog = !1">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
import _ from 'lodash';
export default {
  components: {
    Paging,
    userInfo,
  },
  data() {
    return {
      dislogaudit: !1,
      disloglog: !1,
      list: [],
      auditLog: [],
      areaList: [],
      page: 1,
      rows: 10,
      total1: 0,
      total: 0,
      id: '',
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        case_name: '',
        start_time: '',
        end_time: '',
        city_id: [],
        keyword: '',
      },
      auditForm: {
        status: 1,
        reason: '',
        price: '',
      },
      rules: {
        reason: { required: true, message: '请填写驳回原因', trigger: 'blur' },
        price: { required: true, message: '请填写广告价格', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        case_name: '',
        start_time: '',
        end_time: '',
        city_id: [],
        keyword: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    updateData1(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getAuditLog();
      } else {
        this.page = val;
        this.getAuditLog();
      }
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.city_id.length) {
        searchForm.city_id.length > 1 ? (searchForm.city_id = searchForm.city_id[1]) : (searchForm.city_id = searchForm.city_id[0]);
      } else {
        searchForm.city_id = '';
      }
      this.$axios.post(this.$api.samecity.rights.sameCityAd, searchForm).then(res => {
        if (res.code == 200) {
          this.total = res.result.total;
          this.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    audit(row) {
      this.id = row.id;
      this.auditForm = {
        status: 1,
        reason: '',
        price: '',
      };
      this.dislogaudit = !0;
    },
    seeLog(id) {
      this.id = id;
      this.page = 1;
      this.rows = 5;
      this.disloglog = !0;
      this.getAuditLog();
    },
    getAuditLog() {
      this.$axios
        .post(this.$api.samecity.rights.auditInfo, {
          id: this.id,
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.auditLog = res.result.list;
            this.total1 = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleAudit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: this.id,
            status: this.auditForm.status,
          };
          if (data.status == -1) {
            data.reason = this.auditForm.reason;
          } else {
            data.price = this.auditForm.price;
          }
          let url = data.status == 1 ? this.$api.samecity.rights.auditSuccess : this.$api.samecity.rights.auditError;
          this.$axios.post(url, data).then(res => {
            if (res.code == 200) {
              this.getList();
              this.dislogaudit = !1;
              this.$message.success('处理成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            for (let y in list[i]._child) delete list[i]._child[y]._child;
          }
          this.areaList = list;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  .auditForm .el-form-item {
    margin-bottom: 10px;
  }
  .image {
    display: flex;
    justify-content: center;
    video {
      margin-left: 20px;
      height: 70px;
    }
  }
}
</style>